@import '../variables.scss';
@import 'react-pro-sidebar/dist/scss/styles.scss';

.navbar-container {
  position: fixed;
  top: $sidebar-height;
  height: 100%;
  z-index: 1;

  .pro-sidebar {
    &.collapsed {
      .menu-title {
        display: none;
      }

      .pro-sidebar-inner ul:hover {
        position: fixed;
        width: $sidebar-width;
        background-color: white;
      }
    }

    nav.pro-menu {
      padding-top: 0;

      .menu-title {
        padding: 15px 20px 0 20px;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: #2d2c2c;
        font-weight: 600;
      }

      .active {
        color: #2d2c2c !important;
      }

      .pro-icon-wrapper {
        margin-right: 0 !important;
        margin-left: 4px;
      }
    }
  }
}
