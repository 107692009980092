$text-color: #313131;

body {
  overflow-y: overlay;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f5f6f8;
  -webkit-font-smoothing: antialiased;
  color: $text-color;
  font-family: sans-serif;
}

.opacity-hover:hover {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.filter-grayscale {
  filter: grayscale(100%);
}
