.forgot-password {
  text-decoration: underline;
  color: #2d2c2c;
}

.box-login {
  justify-content: center;
  height: 100vh;
  align-items: center;
  display: flex;

  .logo {
    width: 72px;
  }

  form {
    width: 100%;
  }

  div.box-login-content > div {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    justify-content: center;

    min-height: 400px;
    padding: 20px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
    color: #000;

    .box-logo {
      text-align: center;
      margin-bottom: 30px;
    }

    .box-login-input {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      color: #b1b0b0;

      svg {
        position: absolute;
        margin: 0 0 0 15px;
      }

      input {
        border-radius: 20px;
        font-size: 0.9rem;
        height: 44px;
        background: #f2f2f2;
        font-weight: 500;
        padding: 5px 15px 5px 40px;
        border: none;
        width: 100%;

        &:focus,
        &:hover,
        &:active {
          outline: 0px !important;
          -webkit-appearance: none;
          box-shadow: none !important;
        }

        &:focus,
        &:active {
          background: rgba(0, 0, 0, 0.1);
          color: #2d2c2c;

          &::placeholder,
          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder {
            color: #2d2c2c;
            opacity: 1; /* Firefox */
          }
        }
      }
    }
  }
}

button.btn-submit {
  font-size: 14px;
  height: 40px;
  padding: 0 20px;
  border: none;
  width: 200px;
}
